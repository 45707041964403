var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - Add Location" } }),
      _c("gov-back-link", { attrs: { to: { name: "locations-index" } } }, [
        _vm._v("Back to locations")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Locations")
                  ]),
                  _c("gov-heading", { attrs: { size: "m" } }, [
                    _vm._v("Add location")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "The locations will appear on the service pages which will inform\n          people of where to find your service/organisation"
                    )
                  ]),
                  _c("location-form", {
                    attrs: {
                      errors: _vm.form.$errors,
                      address_line_1: _vm.form.address_line_1,
                      address_line_2: _vm.form.address_line_2,
                      address_line_3: _vm.form.address_line_3,
                      city: _vm.form.city,
                      county: _vm.form.county,
                      postcode: _vm.form.postcode,
                      country: _vm.form.country,
                      has_induction_loop: _vm.form.has_induction_loop,
                      has_wheelchair_access: _vm.form.has_wheelchair_access,
                      has_accessible_toilet: _vm.form.has_accessible_toilet
                    },
                    on: {
                      "update:address_line_1": function($event) {
                        return _vm.$set(_vm.form, "address_line_1", $event)
                      },
                      "update:address_line_2": function($event) {
                        return _vm.$set(_vm.form, "address_line_2", $event)
                      },
                      "update:address_line_3": function($event) {
                        return _vm.$set(_vm.form, "address_line_3", $event)
                      },
                      "update:city": function($event) {
                        return _vm.$set(_vm.form, "city", $event)
                      },
                      "update:county": function($event) {
                        return _vm.$set(_vm.form, "county", $event)
                      },
                      "update:postcode": function($event) {
                        return _vm.$set(_vm.form, "postcode", $event)
                      },
                      "update:country": function($event) {
                        return _vm.$set(_vm.form, "country", $event)
                      },
                      "update:has_induction_loop": function($event) {
                        return _vm.$set(_vm.form, "has_induction_loop", $event)
                      },
                      "update:has_wheelchair_access": function($event) {
                        return _vm.$set(
                          _vm.form,
                          "has_wheelchair_access",
                          $event
                        )
                      },
                      "update:has_accessible_toilet": function($event) {
                        return _vm.$set(
                          _vm.form,
                          "has_accessible_toilet",
                          $event
                        )
                      },
                      "update:image_file_id": function($event) {
                        _vm.form.image_file_id = $event
                      },
                      clear: function($event) {
                        return _vm.form.$errors.clear($event)
                      }
                    }
                  }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Creating...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Create")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }